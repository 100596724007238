<template>
  <div class="project-settings-wrap">
      <section class="list" v-loading="loading">
        <el-button style="margin-bottom: 15px;" size="mini" @click="showDialog">发送通知</el-button>
        <el-table :data="tableData" border ref="table" style="width: 100%">
          <el-table-column  :resizable="false" prop="title" align="center" label="标题"></el-table-column>
          <el-table-column  :resizable="false" prop="msg" align="center" label="内容"/>
          <el-table-column  :resizable="false" prop="createDate" align="center" width="160px" label="发送时间">
            <template scope="{ row }">
                {{ dateFormat(row.createDate) }}
              </template>
            </el-table-column>
        </el-table>
        <section class="list-bottom">
          <span class="count">共{{count}}条记录</span>
          <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
                         @size-change="sizeChange" @current-change="currentChange" :total="count">
          </el-pagination>
        </section>
      </section>
    </section>
    <el-dialog title="通知" :visible.sync="dialogVisible" width="40%">
      <el-form ref="form" label-width="50px">
        <el-form-item label="标题">
          <el-input v-model="title" size="small" placeholder="请输入通知标题"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="msg" size="small" placeholder="请输入通知内容" type="textarea" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleFun">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
  import { sendNotification,deleteNotification,listSendRecord } from '@/api/notice'
export default {
  mounted () {
    this.getList()
  },
  data () {
    return ({
      count: 0,
      currentPage: 1,
      size: 10,
      loading:false,
      dialogVisible:false,
      tableData: [],
      title:'',
      msg:''
    })
  },
  methods: {
    dateFormat (date) {
      if(date)  date = date.replace(/-/g,"/").substr(0,date.indexOf('.'))
      var t = new Date(date)
      var year=t.getFullYear(),
     　　 month=t.getMonth()+1,
     　　 day=t.getDate(),
    　　  hour=t.getHours(),
    　　  min=t.getMinutes(),
    　　  sec=t.getSeconds()
　　  var newTime = year + '-' +
    　　  (month < 10 ? '0'+ month : month) + '-' +
   　　   (day <10 ? '0' + day : day) + ' ' +
    　　  (hour <10 ? '0' + hour : hour) + ':' +
    　　  (min <10 ? '0' + min : min) + ':' +
    　　  (sec <10 ? '0' + sec : sec)
　　  return newTime
    },
    showDialog() {
      this.dialogVisible = true
      this.title = ''
      this.msg = ''
    },

    async handleFun() {
      this.dialogVisible = false
      const res = await sendNotification({ title:this.title,msg:this.msg })
      if(res.code == 0){
        this.$message.success('发送成功')
        this.getList()
      }else{
        this.$message.error(res.msg)
      }
    },
    search(){
      this.currentPage = 1
      this.getList()
    },
    async getList () {
      this.loading = true
      const res = await listSendRecord({ currentPage: this.currentPage, pageSize: this.size })
      this.tableData = res.data
      this.count = res.count
      this.loading = false
    },

    sizeChange: async function (i) {
      this.loading = true
      this.currentPage = 1
      this.size = i
      this.getList()
    },
    currentChange: async function (i) {
      this.loading = true
      this.currentPage = i
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
  .project-settings-wrap {
    width: 100%;
    min-height: 100%;
    .filter {
      width: 100%;
      // height: 70px;
      background: #fff;
      border-radius: 2px;
      padding-top: 15px;
      padding-left: 20px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    }
    .list {
      width: 100%;
      margin-top: 20px;
      min-height: 100%;
      background: #fff;
      border-radius: 2px;
      padding: 20px 20px;
      box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      .btn-wrap {
        margin-bottom: 25px;
      }

      .list-bottom {
        display: flex;
        justify-content: space-between;
        .count {
          line-height: 32px;
        }
      }
      .selected {
        background: rgba(64,158,255, .2);
      }

    }
  }
</style>
